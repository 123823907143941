import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';
import MeasureTool from '../../../../lib/measuretool-googlemaps-v3/src';
import { WeatherOption } from './map.d';
import { Unit } from 'app/modules/settings/settings.context.d';
import { weatherImperialOptions, weatherMetricOptions } from './map.model';

// google map settings
const GOOGLE_MAP_API_KEY = 'AIzaSyBMLOqCBfQby-SpbQ__otKm7pg46qG6j8s';
const GOOGLE_MAP_ID = '90f559dd3d1ba78f';
const DEFAULT_GOOGLE_MAP_OPTIONS = {
  mapId: GOOGLE_MAP_ID,
  center: { lat: 0, lng: 0 },
  zoom: 6,
  disableDefaultUI: true,
  streetViewControl: false,
  mapTypeControl: false,
  zoomControl: false,
};

export const GOOGLE_MAPS_LOADER_PARAMETERS: LoaderOptions = {
  apiKey: GOOGLE_MAP_API_KEY,
  libraries: ['geometry'],
  version: 'beta',
};

// aeris api settings
const AERIS_ID = import.meta.env.VITE_AERIS_ID;
const AERIS_SECRET = import.meta.env.VITE_AERIS_SECRET;

/**
 *
 */
export const init = async ({
  options,
  element,
  setMap,
  setInitialized,
  setIsStreetViewMode,
  onMapLoaded,
}: {
  options: google.maps.MapOptions;
  element: HTMLDivElement | null;
  setMap: (map: google.maps.Map) => void;
  setInitialized: (initialized: boolean) => void;
  setIsStreetViewMode: (isStreetViewMode: boolean) => void;
  onMapLoaded?: (map: google.maps.Map) => void;
}) => {
  if (!element) return;

  const loader = new Loader(GOOGLE_MAPS_LOADER_PARAMETERS);
  
  await loader.load();

  const google = window.google;
  const googleMapOptions = { ...DEFAULT_GOOGLE_MAP_OPTIONS, ...options };
  const map = new google.maps.Map(element, googleMapOptions);

  const thePanorama = map.getStreetView();

  google.maps.event.addListener(thePanorama, 'visible_changed', () => {
    setIsStreetViewMode(thePanorama.getVisible());
  });

  setMap(map);
  setInitialized(true);
  onMapLoaded && onMapLoaded(map);
};

/**
 *
 * @param param0
 */
export const setListeners = ({
  map,
  setZoom,
  setTilt,
  setHeading,
}: {
  map: google.maps.Map;
  setZoom: (zoom: number) => void;
  setTilt: (tilt?: number) => void;
  setHeading: (heading?: number) => void;
}) => {
  const google = window.google;
  google.maps.event.addListener(map, 'zoom_changed', () => {
    const zoom = map.getZoom();
    setZoom(zoom || 0);
  });


  google.maps.event.addListener(map, 'tilt_changed', () => {
    setTilt(map.getTilt());
  });

  google.maps.event.addListener(map, 'tilt_changed', () => {
    setHeading(map.getHeading());
  });
};

/**
 *
 */
export const checkOverlayUnit = ({
  weatherOverlay,
  weatherUnit,
  setWeatherOverlay,
}: {
  weatherOverlay?: string;
  weatherUnit: Unit | undefined;
  setWeatherOverlay: (weatherOverlay?: string) => void;
}) => {
  let options: WeatherOption[] = [];
  let overlay = weatherOverlay;
  // check if it is imperial unit
  if (overlay && weatherUnit?.id === 'farenheit') {
    options = weatherImperialOptions;
    overlay = overlay.replace(/-metric/g, '');
  }

  // check if it is metric unit
  if (overlay && weatherUnit?.id !== 'farenheit') {
    options = weatherMetricOptions;
  }
  const validate = new RegExp(`^${overlay}`);
  const newWeatherOverlay = options.find(
    ({ id }) => validate.test(id)
  );

  setWeatherOverlay(newWeatherOverlay?.id);
};

/**
 *
 * @param map
 */
export const changeMapOverlay = ({
  map,
  weatherOverlay,
}: {
  weatherOverlay?: string;
  map?: google.maps.Map;
}) => {
  if (map) map.overlayMapTypes?.clear();
  if (weatherOverlay) {
    const tile = new google.maps.ImageMapType({
      getTileUrl: (coord: any, zoom: any) => {
        return `https://maps.aerisapi.com/${AERIS_ID}_${AERIS_SECRET}/${weatherOverlay}/${zoom}/${coord.x}/${coord.y}/current.png`;
      },
      tileSize: new google.maps.Size(256, 256),
    });

    if (map) {
      map.overlayMapTypes.insertAt(0, tile as google.maps.MapType);
    }
  }
};

/**
 *
 * @param map
 */
export const renderMeasureTool = ({
  map,
  setMeasureTool,
  distanceUnit,
}: {
  map?: google.maps.Map;
  setMeasureTool: (measureTool: MeasureTool) => void;
  distanceUnit: Unit | undefined;
}) => {
  // load measure tool
  // eslint-disable-next-line @typescript-eslint/no-var-requires

  let unit = MeasureTool.UnitTypeId.METRIC;

  if (distanceUnit?.id === 'ft' || distanceUnit?.id === 'mi') {
    unit = MeasureTool.UnitTypeId.IMPERIAL;
  }

  const measureTool = new MeasureTool(map, {
    contextMenu: false,
    unit,
  });

  setMeasureTool(measureTool);
};

/**
 *
 */
export const fitBounds = ({
  bounds,
  map,
}: {
  bounds: google.maps.LatLng[];
  map: google.maps.Map;
}) => {
  const destination = new google.maps.LatLng({
    lat: bounds[0].lat(),
    lng: bounds[0].lng(),
  });
  
  const animatedPanTo = (map: google.maps.Map, animatedDestination: google.maps.LatLng, duration: number) => {
    const start = map.getCenter() || {} as { lat: number; lng: number };
    const startTimestamp = performance.now();

    const animate = () => {
      const elapsed = performance.now() - startTimestamp;
      const progress = Math.min(elapsed / duration, 1);
      const easing = (t: number) => t * (2 - t);
      const interpolatedLatLng = google.maps.geometry.spherical.interpolate(start, animatedDestination, easing(progress));
      map.panTo(interpolatedLatLng);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    }

    animate();
  }

  const panDuration = 500;
  animatedPanTo(map, destination, panDuration);
};
